import React, { useEffect, useMemo, useRef, useState } from "react";
import NULLproductos from "../../assets/images/NULL/nullproducts.svg";
import imgnull from "../../assets/images/NULL/null.webp";

export const SlidePhotos = ({ photos, alt, setImageLoad, short, ...more }) => {
  const photo = useRef();
  const [indexImage, setIndexImage] = useState(0);

  const getPhotosWithoutNull = useMemo(() => {
    if (Array.isArray(photos))
      return photos?.filter((photo) => {
        return photo != null && photo !== "";
      });
  }, [photos]);

  useEffect(() => {
    if (Array.isArray(photos))
      if (getPhotosWithoutNull?.length > 1) {
        const timeoutId = setTimeout(() => {
          setIndexImage((index) =>
            index >= getPhotosWithoutNull.length - 1 ? 0 : index + 1
          );
        }, 8000);

        return () => clearTimeout(timeoutId);
      }
  }, [getPhotosWithoutNull, indexImage, photos]);

  return (
    <div style={{ position: "relative" }}>
      <img
        ref={photo}
        onLoad={(e) => {
          e.target.src = getPhotosWithoutNull[indexImage]
            ? getPhotosWithoutNull[indexImage]
            : imgnull;
          if (setImageLoad) {
            setImageLoad(true);
          }
        }}
        src={imgnull}
        alt={alt}
        decoding="async"
        {...more}
      />

      {getPhotosWithoutNull?.length > 1 ? (
        <section
          style={{ gap: short ? 5 : "", left: short ? 5 : "" }}
          className="myne-catalogo-product-image__slide"
        >
          {getPhotosWithoutNull?.map((item, i) =>
            item !== "" ? (
              <div
                onMouseEnter={() => setIndexImage(i)}
                style={{
                  width: 7,
                  height: 7,
                  cursor: "pointer",
                  borderRadius: "100%",
                  background: indexImage === i ? "#aa00ff" : "#FFFFFFDE",
                  border: "1px solid #FFFFFFDE",
                }}
              ></div>
            ) : (
              ""
            )
          )}
        </section>
      ) : (
        ""
      )}
    </div>
  );
};
