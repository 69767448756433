import React, { useEffect, useRef, useState } from "react";
import { analyticsEvent } from "../../providers/analytics";
import {
  useOnClickOutside,
  useWindowDimensions,
} from "../../providers/reducer";
import { useStateValue } from "../../providers/StateProvider";
import { changeLanguage } from "../../providers/strings";
import imgnull from "../../assets/images/NULL/null.webp";
import Marquee from "react-fast-marquee";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SlidePhotos } from "../Photos/SlidePhotos";

function CardProduct({
  product,
  ifList,
  index,
  modaladdproduct,
  setModaladdproduct,
  setamountproduct,
  productWithoutStock,
}) {
  const { width } = useWindowDimensions();
  const [{ activeMoeda, activeLaguage, activeLaguageStrings }] =
    useStateValue();

  // MODAL VIEW PHOTO
  const [modalViewPhoto, setModalViewPhoto] = useState(false);
  // MODAL CLICK OFF
  const modalViewPhotoRef = useRef();
  useOnClickOutside(modalViewPhotoRef, () => setModalViewPhoto(false));

  const checkProductUnavailable =
    productWithoutStock === 2 && product?.stock <= 0;

  const [imageLoad, setImageLoad] = useState(false);

  const [imageProduct, setImageProduct] = useState(false);

  useEffect(() => {
    if (product?.name === "prodto testt") {
      console.log(product);
    }
    if (product?.images != null) {
      setImageProduct(product?.images);
    } else {
      if (product?.image != null) {
        setImageProduct([product?.image, "", ""]);
      } else {
        setImageProduct(null);
      }
    }
  }, [product]);

  return productWithoutStock === 3 && product?.stock <= 0 ? (
    ""
  ) : (
    <>
      {/* MODAL VIEW PHOTO */}
      <div
        style={{
          zIndex: 9999999999,
          top: 0,
          bottom: 0,
          opacity: modalViewPhoto ? "5" : "0",
          visibility: modalViewPhoto ? "visible" : "hidden",
        }}
        className="myne-modal"
      >
        <div
          ref={modalViewPhotoRef}
          style={{ transform: modalViewPhoto ? "scale(1)" : "scale(0.7)" }}
          className="myne-modal__view--photo"
        >
          {modalViewPhoto ? (
            <>
              <IconClose
                className="myne-modal--animation-scale"
                onClick={() => setModalViewPhoto(false)}
              />
              <SlidePhotos
                photos={imageProduct}
                alt={product?.name + "-image"}
              />
              {/* <img
                onLoad={(e) => {
                  e.target.src = imageProduct;
                }}
                src={imgnull}
                alt={product?.name + "-image"}
                decoding="async"
              /> */}
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* CARD */}
      <div
        style={{
          marginRight:
            width >= 1440 && width <= 1467
              ? (index + 1) % 3 === 0
                ? 0
                : ""
              : "",
          width: ifList ? "100%" : "",
          height: ifList ? 220 : "",
          flexDirection: ifList ? "row" : "",
        }}
        onClick={() => {
          if (!checkProductUnavailable) {
            setModaladdproduct({ ...product });
            analyticsEvent("PRODUCT_CLICKED");
            setamountproduct(1);
          }
        }}
        className={
          ifList
            ? checkProductUnavailable
              ? "myne-modal--animation-top myne-catalogo-product myne-catalogo-product__list myne-catalogo-product__unavailable"
              : "myne-modal--animation-top myne-catalogo-product myne-catalogo-product__list"
            : checkProductUnavailable
            ? "myne-modal--animation-top myne-catalogo-product myne-catalogo-product__unavailable"
            : "myne-modal--animation-top myne-catalogo-product"
        }
      >
        {/* IMAGE */}
        <section
          style={{
            width: ifList ? 220 : "",
            maxWidth: ifList ? 220 : "",
            minWidth: ifList ? 220 : "",
            height: ifList ? "100%" : "",
          }}
          className="myne-catalogo-product-image"
        >
          <section>
            {imageProduct ? (
              <IconViewImage
                onMouseEnter={() => {
                  if (!checkProductUnavailable) {
                    setModalViewPhoto(true);
                  }
                }}
              />
            ) : (
              ""
            )}
          </section>
          {imageProduct ? (
            <>
              {!imageLoad ? (
                <div
                  style={{ zIndex: 2, position: "absolute", opacity: "0.9" }}
                  className="myne-loading-icon"
                >
                  <div style={{ transform: "scale(0.70)" }}>
                    <div className="myne-circle-loading-3"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <SlidePhotos
                photos={imageProduct}
                alt={product?.name + "-image"}
                setImageLoad={setImageLoad}
              />

              {/* <LazyLoadImage
                style={{
                  borderRadius: ifList ? "8px 0 0 8px" : "",
                  opacity: imageLoad ? 1 : 0,
                }}
                onLoad={(e) => {
                  e.target.src = imageProduct;
                  setImageLoad(true);
                }}
                effect="blur"
                alt={product?.name + "-image"}
                src={imageProduct}
              /> */}
            </>
          ) : (
            <div>
              <IconImage />
            </div>
          )}
          {!ifList ? (
            <button className="btn-hover-add-product">
              {checkProductUnavailable
                ? "INDISPONÍVEL"
                : changeLanguage(
                    activeLaguageStrings?.buttonProduct,
                    "COMPRAR",
                    activeLaguage
                  )}
            </button>
          ) : (
            ""
          )}
        </section>
        {/* INFOS */}
        <section
          style={{
            width: ifList ? "100%" : "",
            height: ifList ? 220 : "",
            padding: ifList ? 24 : "",
          }}
          className="myne-catalogo-product-description"
        >
          {/* NAME */}
          <p title={product?.name}>{product?.name}</p>
          <h5>
            {product?.salePrice?.toLocaleString("pt-br", {
              style: "currency",
              currency: activeMoeda?.currencyId
                ? activeMoeda?.currencyId
                : "BRL",
            })}
          </h5>
          {/* RESPONSIVE */}
          <div className="myne-catalogo-product-description__responsive">
            <p title={product?.name}>{product?.name}</p>
            <h5>
              {product?.salePrice?.toLocaleString("pt-br", {
                style: "currency",
                currency: activeMoeda?.currencyId
                  ? activeMoeda?.currencyId
                  : "BRL",
              })}
            </h5>
          </div>
          {/* DESCRIPTION */}
          {product?.description !== "" && product?.description !== null ? (
            <span
              title={product?.description}
              style={{ wordBreak: "break-all" }}
            >
              {`${product?.description}`.substring(0, 55)}
              {`${product?.description?.length >= 55 ? "..." : ""}`}
            </span>
          ) : (
            <span style={{ color: "rgba(0, 0, 0, 0.36)" }}>
              {changeLanguage(
                activeLaguageStrings?.notDetailsProduct,
                "Não foram informados detalhes sobre o produto.",
                activeLaguage
              )}
            </span>
          )}

          <div className="myne-catalogo-product-categories">
            {product?.categories?.length >= 5 ? (
              <Marquee
                pauseOnHover={true}
                speed={35}
                gradientWidth={10}
                gradient={true}
                className="marquee"
                direction={"right"}
              >
                {product?.categories?.map((categorie, i) => (
                  <h4>
                    {categorie?.name}
                    {product?.categories?.length - 1 === i ? "" : ","}
                  </h4>
                ))}
              </Marquee>
            ) : (
              product?.categories?.map((categorie, i) => (
                <h4>
                  {categorie?.name}
                  {product?.categories?.length - 1 === i ? "" : ","}
                </h4>
              ))
            )}
          </div>

          {/* BUTTON SUBMIT */}
          <button
            style={{
              display: ifList ? "flex" : "none",
              width: checkProductUnavailable ? 110 : "",
            }}
            className="btn-hover-add-product btn-hover-add-product-second"
          >
            {checkProductUnavailable
              ? "Indisponível"
              : changeLanguage(
                  activeLaguageStrings?.buttonProduct?.charAt()?.toUpperCase() +
                    activeLaguageStrings?.buttonProduct
                      ?.toLowerCase()
                      ?.substring(1),
                  "Comprar",
                  activeLaguage
                )}
          </button>
        </section>
      </div>
    </>
  );
}

export default CardProduct;

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="white"
    />
  </svg>
);

const IconViewImage = ({ ...rest }) => (
  <svg
    width="26"
    {...rest}
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="13" fill="white" />
    <path
      d="M15.6367 14.5117H15.0391L14.8281 14.3008C15.707 13.2461 16.1992 11.8047 15.918 10.293C15.5664 8.21875 13.8438 6.53125 11.7344 6.28516C8.57031 5.89844 5.89844 8.57031 6.28516 11.7344C6.53125 13.8438 8.21875 15.5664 10.293 15.918C11.8047 16.1992 13.2461 15.707 14.3008 14.8281L14.5117 15.0391V15.6367L17.7109 18.8008C17.9922 19.1172 18.4844 19.1172 18.8008 18.8008C19.1172 18.4844 19.1172 17.9922 18.8008 17.7109L15.6367 14.5117ZM11.1367 14.5117C9.27344 14.5117 7.76172 13 7.76172 11.1367C7.76172 9.27344 9.27344 7.76172 11.1367 7.76172C13 7.76172 14.5117 9.27344 14.5117 11.1367C14.5117 13 13 14.5117 11.1367 14.5117ZM11.1367 9.23828C10.9258 9.23828 10.75 9.41406 10.75 9.625V10.75H9.625C9.41406 10.75 9.23828 10.9258 9.23828 11.1367C9.23828 11.3477 9.41406 11.4883 9.625 11.4883H10.75V12.6133C10.75 12.8242 10.9258 13 11.1367 13C11.3477 13 11.4883 12.8242 11.4883 12.6133V11.4883H12.6133C12.8242 11.4883 13 11.3477 13 11.1367C13 10.9258 12.8242 10.75 12.6133 10.75H11.4883V9.625C11.4883 9.41406 11.3477 9.23828 11.1367 9.23828Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const IconImage = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 21.3125V2.6875C24 1.1875 22.8125 0 21.3125 0H2.6875C1.1875 0 0 1.1875 0 2.6875V21.3125C0 22.8125 1.1875 24 2.6875 24H21.3125C22.8125 24 24 22.8125 24 21.3125ZM7.875 14.625L10.6875 18L14.8125 12.6875C15.0625 12.375 15.625 12.375 15.875 12.6875L20.5625 18.9375C20.875 19.375 20.5625 20 20 20H4C3.4375 20 3.1875 19.375 3.5 18.9375L6.8125 14.6875C7.0625 14.3125 7.5625 14.3125 7.875 14.625Z"
      fill="black"
      fill-opacity="0.36"
    />
  </svg>
);
