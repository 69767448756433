import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "./Header";

import "../../styles/CatalogoDigital/Catalogo.css";
import Radio from "@mui/material/Radio";
import CardProduct from "../../components/Cards/CardProduct";
import InputForm from "../../components/inputs/InputForm";
import Footer from "./Footer";
import NULLproductos from "../../assets/images/NULL/nullproducts.svg";
import { useStateValue } from "../../providers/StateProvider";
import { db } from "../../services/Firebase";
import { Redirect, useHistory, useParams } from "react-router-dom";
import {
  FilterMoeda,
  removeAcento,
  useOnClickOutside,
  useWindowDimensions,
} from "../../providers/reducer";
import {
  addItemCART,
  formatTextMessage,
  pedirWhatsapp,
} from "../../providers/catalogo";
import { changeLanguage, stringsEN, stringsES } from "../../providers/strings";
import { analyticsEvent } from "../../providers/analytics";
import Btntopscrool from "../../components/Buttons/Btntopscrool";
import { strAllCategoriesProducts } from "../../providers/categories";
import { getExhibitions, getFilters } from "../../utils/filtersAndExhibitions";
import { Product } from "../../providers/products";
import { SlidePhotos } from "../../components/Photos/SlidePhotos";

function Catalago({ subdomain, carrinho, setCarrinho }) {
  const [stores, setStores] = useState();
  const history = useHistory();
  const { store } = useParams();
  const [productWithoutStock, setProductWithoutStock] = useState(null);
  const storeNAME = subdomain ? subdomain : store;
  const [
    { messages, activeMoeda, activeLaguage, activeLaguageStrings },
    dispatch,
  ] = useStateValue();

  const docReference = db.collection("stores");
  const [loading, setLoading] = useState(true);
  const [loadingProductsSearch, setLoadingProductsSearch] = useState(false);
  const [productsAlgoliaMigrateToSearch, setProductsAlgoliaMigrateToSearch] =
    useState(false);

  // GET STORE
  useEffect(() => {
    setLoading(true);
    const fecthstores = () => {
      docReference
        .doc(storeNAME)
        .get()
        .then((data) => {
          setStores(data.data());
          dispatch({
            type: "SET_STORE_DATA",
            store_data: data.data(),
          });
          if (data.data()) {
            setLoading(false);
            analyticsEvent("CATALOG_OPENED");
            setProductWithoutStock(data.data()?.productWithoutStock);
            setProductsAlgoliaMigrateToSearch(
              data.data()?.productsAlgoliaMigrateToSearch
            );
          }
        });
    };
    fecthstores();
  }, [storeNAME]);

  // LOADING TIME
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, [2200]);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [loading]);

  // REDIRECT IF NOT STORE
  useEffect(() => {
    if (!loading && !stores) {
      window.location.href = `https://myneapp.com.br/`;
    }
  }, [loading, stores]);

  const [products, setProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const refInputSearch = useRef();

  // Filter and Exhibitions

  const filters = getFilters(activeLaguageStrings, activeLaguage);
  const exhibitions = getExhibitions(activeLaguageStrings, activeLaguage);

  const [filterSelect, setFilterSelect] = useState(filters[0].index);
  const [exhibitionSelect, setExhibitionSelect] = useState(
    exhibitions[0].index
  );

  const [lastKey, setLastKey] = useState("");
  const [nextProductsLoading, setNextProductsLoading] = useState(false);
  const [selectCategories, setSelectCategories] = useState([
    strAllCategoriesProducts,
  ]);

  const getProducts = useCallback(() => {
    setNextProductsLoading(true);
    Product.productsFirstBatch(
      storeNAME,
      selectCategories,
      filterSelect,
      filters
    )
      .then((res) => {
        setProducts(res.products);
        setLastKey(res.lastKey);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setNextProductsLoading(false));
  }, [filterSelect, selectCategories, storeNAME]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const fetchMoreProducts = (key) => {
    if (products?.length >= Product.pageProductsLimit) {
      if (!searchProduct) {
        if (key?.id?.length > 0) {
          setNextProductsLoading(true);
          Product.productsNextBatch(
            storeNAME,
            key,
            filterSelect,
            filters,
            selectCategories
          )
            .then((res) => {
              setLastKey(res.lastKey);
              setProducts(products.concat(res.products));
              setNextProductsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setNextProductsLoading(false);
            })
            .finally(() => {
              setNextProductsLoading(false);
            });
        }
      }
    }
  };

  // Infinite Scroll
  const refScroll = useRef();
  const refInit = useRef(false);

  const [topScroll, setTopScroll] = useState(false);

  const handleScrollProducts = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = refScroll.current;

    if (scrollTop > 200) {
      setTopScroll(true);
    } else {
      setTopScroll(false);
    }

    const check = scrollHeight - Math.ceil(scrollTop) === clientHeight;

    if (check) {
      if (nextProductsLoading) {
      } else if (lastKey?.id?.length > 0) {
        fetchMoreProducts(lastKey);
      } else {
      }
    } else {
    }
  }, [refScroll, lastKey, nextProductsLoading]);

  useEffect(() => {
    if (refScroll?.current) {
      refScroll?.current?.addEventListener("scroll", handleScrollProducts);
      return () =>
        refScroll?.current?.removeEventListener("scroll", handleScrollProducts);
    }
  }, [refScroll, handleScrollProducts]);

  const [categories, setCategories] = useState([]);

  // GET CATEGORIES
  useEffect(() => {
    const fecthcategories = () => {
      docReference
        .doc(storeNAME)
        .collection("categories")
        .onSnapshot((snapshot) => {
          setCategories(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              ...{ ...doc.data(), id: doc.id },
            }))
          );
        });
    };
    fecthcategories();
  }, []);

  const removeCategorie = () => {
    setSelectCategories([strAllCategoriesProducts]);
  };

  const existCategorie = (categorie) => {
    return selectCategories?.id === categorie?.id;
  };

  const filterCategories = useMemo(() => {
    return products;
    // if (selectCategories[0] === strAllCategoriesProducts) {
    //   return products;
    // } else {
    //   return products?.filter((product) => {
    //     // --> some
    //     return product?.categories?.some((cate) => {
    //       return selectCategories?.includes(cate?.name);
    //     });
    //   });
    // }
  }, [products]);

  // FILTERS

  const ifList = exhibitionSelect === exhibitions[1].index;

  const handleChange = (event) => {
    const value = parseFloat(event.target.value);

    setFilterSelect(value);

    switch (value) {
      case 0:
        analyticsEvent("LOWEST_PRICE_ORDER_BY_CLICKED");
        break;
      case 1:
        analyticsEvent("BIGGEST_PRICE_ORDER_BY_CLICKED");
        break;
      case 2:
        analyticsEvent("AZ_ORDER_BY_CLICKED");
        break;
      case 3:
        analyticsEvent("ZA_ORDER_BY_CLICKED");
        break;

      default:
        break;
    }
  };
  const handleChange2 = (event) => {
    const value = parseFloat(event.target.value);

    setExhibitionSelect(value);

    switch (value) {
      case 0:
        analyticsEvent("CARD_VISUALIZATION_CLICKED");
        break;
      case 1:
        analyticsEvent("LIST_VISUALIZATION_CLICKED");
        break;

      default:
        break;
    }
  };

  // SEARCH FILTER from ALGOLIA
  const [filterSearchProductsFromAlgolia, setFilterSearchProductsFromAlgolia] =
    useState([]);

  const checkGetFromAlgolia =
    products?.length >= Product.pageProductsLimit &&
    productsAlgoliaMigrateToSearch;

  // GET FROM ALGOLIA
  const getFromAlgolia = useCallback(() => {
    if (searchProduct && checkGetFromAlgolia) {
      setLoadingProductsSearch(true);
      const getSearch = async () => {
        const resultsSearch = await Product.searchProducts(
          searchProduct,
          storeNAME
        ).finally(() => {
          setLoadingProductsSearch(false);
        });
        if (resultsSearch[0]?.hits?.length > 0) {
          setLoadingProductsSearch(false);
        }
        setFilterSearchProductsFromAlgolia(
          resultsSearch[0]?.hits?.map((product) => ({
            id: product?.objectID,
            ...product,
          }))
        );
        console.log(resultsSearch);
      };
      const timeoutSearch = setTimeout(() => getSearch(), [2000]);
      return () => clearTimeout(timeoutSearch);
    } else {
      setFilterSearchProductsFromAlgolia([]);
    }
  }, [checkGetFromAlgolia, searchProduct, storeNAME]);

  useEffect(getFromAlgolia, [getFromAlgolia]);

  // SEARCH PRODUCTS
  const productsFilter = useMemo(() => {
    if (searchProduct?.length > 2) {
      analyticsEvent("PRODUCT_SEARCHED");
    }
    if (searchProduct) {
      const filterSearch = checkGetFromAlgolia
        ? filterSearchProductsFromAlgolia
        : filterCategories?.filter((product) => {
            const filter = (str) =>
              removeAcento(str?.toString()?.toLowerCase());

            return (
              filter(product?.name)?.includes(filter(searchProduct)) ||
              filter(product?.description)?.includes(filter(searchProduct))
            );
          });
      return filterSearch;
    } else {
      return filterCategories;
    }
  }, [
    checkGetFromAlgolia,
    filterSearchProductsFromAlgolia,
    filterCategories,
    searchProduct,
  ]);

  // FILTER
  const filterOrder = useMemo(() => {
    return productsFilter;
    // if (filterSelect === 2) {
    //   return productsFilter?.sort((x, y) => {
    //     var a = x?.salePrice;
    //     var b = y?.salePrice;

    //     if (a > b) {
    //       return -1;
    //     }
    //     if (a < b) {
    //       return 1;
    //     }

    //     return 0;
    //   });
    // } else if (filterSelect === 1) {
    //   return productsFilter?.sort((x, y) => {
    //     return x?.salePrice - y?.salePrice;
    //   });
    // } else if (filterSelect === 3) {
    //   return productsFilter?.sort((x, y) => {
    //     return x?.name?.toString().localeCompare(y.name?.toString());
    //   });
    // } else {
    //   return productsFilter?.sort((x, y) => {
    //     var a = x?.name?.toString()?.toLowerCase();
    //     var b = y?.name?.toString()?.toLowerCase();

    //     if (a > b) {
    //       return -1;
    //     }
    //     if (a < b) {
    //       return 1;
    //     }

    //     return 0;
    //   });
    // }
  }, [productsFilter]);

  const filterOrderEnd = useMemo(() => {
    if (productWithoutStock === 2) {
      return filterOrder?.sort((x, y) => {
        if (x?.stock <= 0) {
          return 1;
        }
        if (y?.stock <= 0) {
          return -1;
        }
        return 0;
      });
    } else {
      return filterOrder;
    }
  }, [filterOrder, productWithoutStock]);

  // TIME MESSAGES
  useEffect(() => {
    if (
      messages.messageProductAdd ||
      messages.messageErro ||
      messages.messageProductsAdd
    ) {
      const timer = setTimeout(() => {
        dispatch({
          type: "SET_MESSAGES",
          messages: {
            messageProductAdd: false,
            messageProductsAdd: false,
            messageErro: false,
          },
        });
      }, [3000]);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [messages]);

  // MODAL
  const [modaladdproduct, setModaladdproduct] = useState(null);
  // AMOUNT MODAL
  const [amountproduct, setamountproduct] = useState(1);

  // MODAL CLICK OFF
  const modaladdproductRef = useRef();
  useOnClickOutside(modaladdproductRef, () => setModaladdproduct(null));

  const { width } = useWindowDimensions();

  // SET MOEDA

  const getMoeda = () => {
    fetch("./moedas.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const moeda = FilterMoeda(data.results, stores);

        dispatch({
          type: "SET_ACTIVE_MOEDA",
          activeMoeda: moeda,
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  // SET MOEDA STORE and LANGUAGE

  useEffect(() => {
    if (stores) {
      getMoeda();

      dispatch({
        type: "SET_ACTIVE_LANGUAGE",
        activeLaguage: stores?.language,
      });
      dispatch({
        type: "SET_ACTIVE_LANGUAGE_STRINGS",
        activeLaguageStrings:
          stores?.language === "pt"
            ? null
            : stores?.language === "en"
            ? stringsEN
            : stores?.language === "es"
            ? stringsES
            : stringsEN,
      });
    }
  }, [stores]);

  // STORE NAME

  useEffect(() => {
    if (stores) {
      document.getElementById(
        "title-myneshop"
      ).innerHTML = `${stores?.storeName} | Myne Shop`;
    }
  }, [stores]);

  // image product
  const [imageProduct, setImageProduct] = useState(false);

  return !loading && !stores ? (
    <Redirect to={"/"} />
  ) : (
    <>
      {/* ---- PAGE CATALOGO ---- */}

      {/* MESSAGE ADD */}
      <div
        className={
          messages.messageProductAdd
            ? "myne-modal_message myne-modal_message-sucess myne-modal_message-show"
            : "myne-modal_message-notshow myne-modal_message myne-modal_message-sucess"
        }
        onClick={() => {
          history.push(`/carrinho`);
          dispatch({
            type: "SET_MESSAGES",
            messages: {
              messageProductAdd: false,
              messageProductsAdd: false,
              messageErro: false,
            },
          });
        }}
      >
        <IconSucess />
        {changeLanguage(
          activeLaguageStrings?.messages?.addedProduct,
          "Produto adicionado ao carrinho!",
          activeLaguage
        )}
      </div>
      {/* MESSAGE ADDS */}
      <div
        style={{ width: 290 }}
        className={
          messages.messageProductsAdd
            ? "myne-modal_message myne-modal_message-sucess myne-modal_message-show"
            : "myne-modal_message-notshow myne-modal_message myne-modal_message-sucess"
        }
        onClick={() => {
          history.push(`/carrinho`);
          dispatch({
            type: "SET_MESSAGES",
            messages: {
              messageProductAdd: false,
              messageProductsAdd: false,
              messageErro: false,
            },
          });
        }}
      >
        <IconSucess />
        {changeLanguage(
          activeLaguageStrings?.messages?.addedProducts,
          "Produtos adicionados ao carrinho!",
          activeLaguage
        )}
      </div>
      {/* MESSAGE ERRO */}
      <div
        className={
          messages.messageErro
            ? "myne-modal_message myne-modal_message-erro myne-modal_message-show"
            : "myne-modal_message-notshow myne-modal_message myne-modal_message-erro"
        }
      >
        <IconErro />
        {changeLanguage(
          activeLaguageStrings?.messages?.error,
          "Algo deu errado. Tente novamente mais tarde.",
          activeLaguage
        )}
      </div>
      {/*  */}
      {/* MODAL ADD */}
      <div
        style={{
          zIndex: 9999999999,
          top: 0,
          bottom: 0,
          opacity: modaladdproduct ? "5" : "0",
          visibility: modaladdproduct ? "visible" : "hidden",
        }}
        className="myne-modal"
      >
        <div
          ref={modaladdproductRef}
          style={{ transform: modaladdproduct ? "scale(1)" : "scale(0.7)" }}
          className="myne-modal__modal-edit-cadastro myne-modal__modal-add-product-sale-item myne-modal__modal-add-product-sale-item-catalogo"
        >
          <div
            style={{
              transform: modaladdproduct ? "scale(1)" : "scale(0.7)",
            }}
            className="myne-modal__modal-add-product-sale-item__container"
          >
            {modaladdproduct ? (
              <>
                <section className="myne-modal--animation-top myne-modal__modal-add-product-sale-item__container-area-product">
                  <div className="myne-modal__modal-add-product-sale-item__container-area-product-itens">
                    {/* IMAGE AND NAME */}
                    {modaladdproduct?.image || modaladdproduct?.images ? (
                      <SlidePhotos
                        short
                        photos={
                          modaladdproduct?.images ?? modaladdproduct?.image
                        }
                        style={{
                          objectFit:
                            modaladdproduct?.image || modaladdproduct?.images
                              ? "cover"
                              : "",
                        }}
                        className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                      />
                    ) : (
                      <IconImage />
                    )}
                    <p>{modaladdproduct?.name}</p>
                    {/* RESPONSIVE */}
                    <div className="myne-modal__modal-add-product-sale-item__container-area-product-itens-responsive">
                      {modaladdproduct?.image || modaladdproduct?.images ? (
                        <SlidePhotos
                          short
                          photos={
                            modaladdproduct?.images ?? modaladdproduct?.image
                          }
                          style={{
                            objectFit:
                              modaladdproduct?.image || modaladdproduct?.images
                                ? "cover"
                                : "",
                          }}
                          className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                        />
                      ) : (
                        <IconImage />
                      )}
                      <p>{modaladdproduct?.name}</p>
                    </div>
                    {/* ADD AND REMOVE AMOUNT */}
                    <section>
                      <IconRemove
                        onClick={() => {
                          if (amountproduct > 1) {
                            setamountproduct((remove) => remove - 1);
                          } else {
                            setamountproduct(1);
                          }
                        }}
                      />
                      <input
                        onChange={(e) => {
                          let v = parseFloat(e.target.value);
                          if (isNaN(amountproduct)) {
                            setamountproduct(1);
                          } else {
                            setamountproduct(v);
                          }
                        }}
                        value={amountproduct}
                      />
                      <IconPlus
                        onClick={() => {
                          setamountproduct((remove) => remove + 1);
                        }}
                      />
                    </section>
                  </div>
                  <section>
                    {amountproduct} x{" "}
                    {modaladdproduct?.salePrice?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: activeMoeda?.currencyId
                        ? activeMoeda?.currencyId
                        : "BRL",
                    })}{" "}
                    ={" "}
                    <b>
                      {(
                        amountproduct * modaladdproduct?.salePrice
                      )?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: activeMoeda?.currencyId
                          ? activeMoeda?.currencyId
                          : "BRL",
                      })}
                    </b>
                  </section>
                </section>

                <div>
                  <a
                    onClick={() =>
                      addItemCART(
                        modaladdproduct,
                        carrinho,
                        amountproduct,
                        setCarrinho,
                        setModaladdproduct,
                        history,
                        dispatch
                      )
                    }
                  >
                    <IconCart />

                    {changeLanguage(
                      activeLaguageStrings?.buttonAddItem,
                      "ADICIONAR ITEM",
                      activeLaguage
                    )}
                  </a>
                  <button
                    onClick={() =>
                      pedirWhatsapp(
                        stores.whatsApp,
                        formatTextMessage(
                          [{ ...modaladdproduct, amount: amountproduct }],
                          amountproduct * modaladdproduct?.salePrice,
                          activeMoeda,
                          activeLaguage
                        )
                      )
                    }
                  >
                    {changeLanguage(
                      activeLaguageStrings?.buttonOrder,
                      "PEDIR POR WHATSAPP",
                      activeLaguage
                    )}
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* CATALOG */}
      <div className="myne-catalogo">
        <Header
          subdomain={subdomain}
          carrinho={carrinho}
          setCarrinho={setCarrinho}
          filters={filters}
          filterSelect={filterSelect}
          categories={categories}
          selectCategories={selectCategories}
          setSelectCategories={setSelectCategories}
          existCategorie={existCategorie}
          removeCategorie={removeCategorie}
          setFilterSelect={setFilterSelect}
          searchProduct={searchProduct}
          setSearchProduct={setSearchProduct}
        />

        {/* LOADING */}
        {loading ? (
          <div style={{ top: -180 }} className="myne-loading">
            <div className="myne-circle-loading"></div>
          </div>
        ) : (
          // CONTENT
          <div ref={refScroll} className="myne-catalogo-content">
            <div style={{ position: "absolute", top: 0 }} ref={refInit}></div>
            <section className="myne-modal--animation-right myne-catalogo-content--filter">
              {/* CATEGORY */}
              {categories?.length > 0 ? (
                <div
                  style={{ marginBottom: 12 }}
                  className="myne-catalogo-content--filter__content myne-catalogo-content--filter__content--categories"
                >
                  <section className="myne-catalogo-content--filter__content-item">
                    <p>Categorias</p>
                    <div className="myne-catalogo-content--filter__content-item-categories">
                      <button
                        className={
                          selectCategories[0] === strAllCategoriesProducts
                            ? "myne-catalogo-content--filter-btn myne-catalogo-content--filter-btn-active"
                            : "myne-catalogo-content--filter-btn"
                        }
                        onClick={() => {
                          setSelectCategories([strAllCategoriesProducts]);
                        }}
                      >
                        Todos
                      </button>

                      {categories?.map((item, i) => (
                        <button
                          className={
                            existCategorie(item)
                              ? "myne-catalogo-content--filter-btn myne-catalogo-content--filter-btn-active"
                              : "myne-catalogo-content--filter-btn"
                          }
                          onClick={() => {
                            removeCategorie(strAllCategoriesProducts);

                            if (existCategorie(item)) {
                              removeCategorie(item);
                              if (selectCategories?.length === 1) {
                                setSelectCategories([strAllCategoriesProducts]);
                              }
                            } else {
                              // setSelectCategories((c) => [...c, item]);
                              setSelectCategories(item);
                            }
                          }}
                        >
                          {item?.name}
                        </button>
                      ))}
                    </div>
                  </section>
                </div>
              ) : (
                ""
              )}
              {/* FILTERTS */}
              <div className="myne-catalogo-content--filter__content">
                <section className="myne-catalogo-content--filter__content-item">
                  <p>
                    {changeLanguage(
                      activeLaguageStrings?.filtersSidebar?.filter1?.title,
                      "Ordenar por",
                      activeLaguage
                    )}
                  </p>
                  {filters?.map((item, i) => (
                    <label
                      style={{ marginTop: item.index === 1 ? "0" : "" }}
                      htmlFor={item.id}
                      key={i}
                    >
                      <Radio
                        checked={filterSelect === item.index}
                        onChange={handleChange}
                        value={item.index}
                        name="radio-buttons"
                        id={item.id}
                      />
                      <p>{item.name}</p>
                    </label>
                  ))}
                </section>
                <section
                  style={{ marginTop: 32 }}
                  className="myne-catalogo-content--filter__content-item"
                >
                  <p>
                    {changeLanguage(
                      activeLaguageStrings?.filtersSidebar?.filter2?.title,
                      "Exibição",
                      activeLaguage
                    )}
                  </p>
                  {exhibitions?.map((item, i) => (
                    <label
                      style={{ marginTop: item.index === 1 ? "0" : "" }}
                      htmlFor={item.id}
                      key={i}
                    >
                      <Radio
                        checked={exhibitionSelect === item.index}
                        onChange={handleChange2}
                        value={item.index}
                        name="radio-buttons"
                        id={item.id}
                      />
                      <p>{item.name}</p>
                    </label>
                  ))}
                </section>
              </div>
              {/* DICA */}
              <div className="myne-catalogo-content--filter__dica">
                <IconDica />
                <p>
                  {changeLanguage(
                    activeLaguageStrings?.dica,
                    "Dica: não realize pagamentos a desconhecidos. As ofertas e produtos apresentados nesse catálogo são de responsabilidade de",
                    activeLaguage
                  )}{" "}
                  <strong>{stores?.ownerName}.</strong>
                </p>
              </div>
            </section>
            {/* ITENS */}
            <section className="myne-catalogo-content--itens">
              {/* SEARCH */}
              <div className="myne-catalogo-content--search">
                <InputForm
                  icon={<IconSearch />}
                  label={changeLanguage(
                    activeLaguageStrings?.search,
                    "Buscar produto",
                    activeLaguage
                  )}
                  search
                  value={searchProduct}
                  resetValue={() => setSearchProduct("")}
                  type="text"
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
              </div>
              {/* PRODUCTS */}
              <div
                style={{ width: width >= 1440 && width <= 1444 ? 708 : "" }}
                className="myne-catalogo-content--products"
              >
                {filterOrderEnd?.length !== 0 ? (
                  filterOrderEnd?.map((product, index) => (
                    <CardProduct
                      index={index}
                      product={product}
                      ifList={ifList}
                      productWithoutStock={productWithoutStock}
                      modaladdproduct={modaladdproduct}
                      setModaladdproduct={setModaladdproduct}
                      setamountproduct={setamountproduct}
                    />
                  ))
                ) : !nextProductsLoading && !loadingProductsSearch ? (
                  <div className="myne-catalogo-content--products__not-itens">
                    <IconAlert />
                    <p>
                      {changeLanguage(
                        activeLaguageStrings?.notResultsSearch,
                        "Resultados não encontratos. Tente novamente buscando por outros termos!",
                        activeLaguage
                      )}
                    </p>
                  </div>
                ) : null}
              </div>
              <div
                className="myne-loading-icon--infinite"
                style={{
                  textAlign: "center",
                }}
              >
                {loadingProductsSearch ? (
                  <div className="myne-loading-icon">
                    <div className="myne-circle-loading-3"></div>
                  </div>
                ) : filterOrderEnd?.length <
                  Product.pageProductsLimit ? null : nextProductsLoading ? (
                  <div className="myne-loading-icon">
                    <div className="myne-circle-loading-3"></div>
                  </div>
                ) : lastKey?.id?.length > 0 ? (
                  <div
                    onClick={() => fetchMoreProducts(lastKey)}
                    className="myne-loading-icon"
                  >
                    <div className="myne-circle-loading-3"></div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* DICA RESPONSIVE */}
              {width <= 1340 ? (
                <div className="myne-catalogo-content--filter__dica myne-catalogo-content--filter__dica-responsive">
                  <IconDica />
                  <p>
                    {changeLanguage(
                      activeLaguageStrings?.dica,
                      "Dica: não realize pagamentos a desconhecidos. As ofertas e produtos apresentados nesse catálogo são de responsabilidade de",
                      activeLaguage
                    )}{" "}
                    <strong>{stores?.ownerName}.</strong>
                  </p>
                </div>
              ) : (
                ""
              )}

              <Btntopscrool
                onClick={() => {
                  if (refInit.current) {
                    refInit.current.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                top={topScroll}
              />
            </section>

            {/* FOOTER */}
            <Footer />
          </div>
        )}
      </div>
    </>
  );
}

export default Catalago;

const IconAlert = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F5F5F5" />
    <path
      d="M12.4531 29H27.5469C29.0469 29 30.0312 27.3125 29.2812 26L21.7344 12.9688C20.9375 11.6562 19.0625 11.6562 18.2656 12.9688L10.7188 26C9.96875 27.3125 10.9531 29 12.4531 29ZM20 22.0156C19.4375 22.0156 19.0156 21.5469 19.0156 20.9844V19.0156C19.0156 18.4531 19.4375 17.9844 20 17.9844C20.5625 17.9844 20.9844 18.4531 20.9844 19.0156V20.9844C20.9844 21.5469 20.5625 22.0156 20 22.0156ZM20.9844 26H19.0156V23.9844H20.9844V26Z"
      fill="#FFBD06"
    />
  </svg>
);

const IconSearch = ({ ...rest }) => (
  <svg
    {...rest}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5156 12.0156H12.7188L12.4375 11.7344C13.6094 10.3281 14.2656 8.40625 13.8906 6.39062C13.4219 3.625 11.125 1.375 8.3125 1.04688C4.09375 0.53125 0.53125 4.09375 1.04688 8.3125C1.375 11.125 3.625 13.4219 6.39062 13.8906C8.40625 14.2656 10.3281 13.6094 11.7344 12.4375L12.0156 12.7188V13.5156L16.2344 17.7344C16.6562 18.1562 17.3125 18.1562 17.7344 17.7344C18.1562 17.3594 18.1562 16.6562 17.7344 16.2812L13.5156 12.0156ZM7.51562 12.0156C5.03125 12.0156 3.01562 10 3.01562 7.51562C3.01562 5.03125 5.03125 3.01562 7.51562 3.01562C10 3.01562 12.0156 5.03125 12.0156 7.51562C12.0156 10 10 12.0156 7.51562 12.0156Z"
      fill="black"
      fill-opacity="0.6"
    />
  </svg>
);

const IconImage = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 21.3125V2.6875C24 1.1875 22.8125 0 21.3125 0H2.6875C1.1875 0 0 1.1875 0 2.6875V21.3125C0 22.8125 1.1875 24 2.6875 24H21.3125C22.8125 24 24 22.8125 24 21.3125ZM7.875 14.625L10.6875 18L14.8125 12.6875C15.0625 12.375 15.625 12.375 15.875 12.6875L20.5625 18.9375C20.875 19.375 20.5625 20 20 20H4C3.4375 20 3.1875 19.375 3.5 18.9375L6.8125 14.6875C7.0625 14.3125 7.5625 14.3125 7.875 14.625Z"
      fill="black"
      fill-opacity="0.36"
    />
  </svg>
);

const IconErro = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 10.9844C9.4375 10.9844 9.01562 10.5625 9.01562 10V6.01562C9.01562 5.45312 9.4375 4.98438 10 4.98438C10.5625 4.98438 10.9844 5.45312 10.9844 6.01562V10C10.9844 10.5625 10.5625 10.9844 10 10.9844ZM10.9844 15.0156H9.01562V13H10.9844V15.0156Z"
      fill="#FE2543"
    />
  </svg>
);

const IconDica = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 13C3 13.375 3.3125 13.6562 3.65625 13.6562H6.34375C6.6875 13.6562 7 13.375 7 13V12.3438H3V13ZM5 0.34375C2.4375 0.34375 0.34375 2.4375 0.34375 5C0.34375 6.59375 1.125 7.96875 2.34375 8.8125V10.3438C2.34375 10.6875 2.625 11 3 11H7C7.375 11 7.65625 10.6875 7.65625 10.3438V8.8125C8.875 7.96875 9.65625 6.59375 9.65625 5C9.65625 2.4375 7.5625 0.34375 5 0.34375ZM6.90625 7.71875L6.34375 8.125V9.65625H3.65625V8.125L3.09375 7.71875C2.1875 7.09375 1.65625 6.09375 1.65625 5C1.65625 3.15625 3.15625 1.65625 5 1.65625C6.84375 1.65625 8.34375 3.15625 8.34375 5C8.34375 6.09375 7.8125 7.09375 6.90625 7.71875Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconCart = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9C12.5625 9 12.9844 8.53125 12.9844 8.01562V6H15C15.5625 6 15.9844 5.53125 15.9844 5.01562C15.9844 4.45312 15.5625 3.98438 15 3.98438H12.9844V2.01562C12.9844 1.45312 12.5625 0.984375 12 0.984375C11.4375 0.984375 11.0156 1.45312 11.0156 2.01562V3.98438H9C8.4375 3.98438 8.01562 4.45312 8.01562 5.01562C8.01562 5.53125 8.4375 6 9 6H11.0156V8.01562C11.0156 8.53125 11.4375 9 12 9ZM6.98438 18C5.90625 18 5.01562 18.8906 5.01562 20.0156C5.01562 21.0938 5.90625 21.9844 6.98438 21.9844C8.10938 21.9844 9 21.0938 9 20.0156C9 18.8906 8.10938 18 6.98438 18ZM17.0156 18C15.8906 18 15 18.8906 15 20.0156C15 21.0938 15.8906 21.9844 17.0156 21.9844C18.0938 21.9844 18.9844 21.0938 18.9844 20.0156C18.9844 18.8906 18.0938 18 17.0156 18ZM8.10938 12.9844H15.5625C16.3125 12.9844 16.9688 12.6094 17.2969 11.9531L20.5312 5.8125C20.8125 5.34375 20.625 4.73438 20.1562 4.5C19.6406 4.21875 19.0312 4.40625 18.7969 4.92188L15.5625 11.0156H8.53125L4.26562 2.01562H2.01562C1.45312 2.01562 0.984375 2.4375 0.984375 3C0.984375 3.5625 1.45312 3.98438 2.01562 3.98438H3L6.60938 11.5781L5.25 14.0156C4.5 15.375 5.48438 17.0156 6.98438 17.0156H18C18.5625 17.0156 18.9844 16.5469 18.9844 15.9844C18.9844 15.4688 18.5625 15 18 15H6.98438L8.10938 12.9844Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const IconRemove = ({ ...rest }) => (
  <svg
    {...rest}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.511719C3.85156 0.511719 0.511719 3.85156 0.511719 8C0.511719 12.1484 3.85156 15.4883 8 15.4883C12.1484 15.4883 15.4883 12.1484 15.4883 8C15.4883 3.85156 12.1484 0.511719 8 0.511719ZM10.9883 8.73828H5.01172C4.58984 8.73828 4.23828 8.42188 4.23828 8C4.23828 7.57812 4.58984 7.26172 5.01172 7.26172H10.9883C11.4102 7.26172 11.7617 7.57812 11.7617 8C11.7617 8.42188 11.4102 8.73828 10.9883 8.73828Z"
      fill="#FE2543"
    />
  </svg>
);

const IconSucess = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM7.28125 14.3125L3.71875 10.7031C3.29688 10.3281 3.29688 9.67188 3.71875 9.29688C4.09375 8.92188 4.70312 8.92188 5.125 9.29688L7.98438 12.1562L14.875 5.3125C15.25 4.89062 15.9062 4.89062 16.2812 5.3125C16.7031 5.6875 16.7031 6.29688 16.2812 6.71875L8.6875 14.3125C8.3125 14.6875 7.70312 14.6875 7.28125 14.3125Z"
      fill="#58CC02"
    />
  </svg>
);

const IconPlus = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM13.9844 10.9844H10.9844V13.9844C10.9844 14.5469 10.5625 15.0156 10 15.0156C9.4375 15.0156 9.01562 14.5469 9.01562 13.9844V10.9844H6.01562C5.45312 10.9844 4.98438 10.5625 4.98438 10C4.98438 9.4375 5.45312 9.01562 6.01562 9.01562H9.01562V6.01562C9.01562 5.45312 9.4375 4.98438 10 4.98438C10.5625 4.98438 10.9844 5.45312 10.9844 6.01562V9.01562H13.9844C14.5469 9.01562 15.0156 9.4375 15.0156 10C15.0156 10.5625 14.5469 10.9844 13.9844 10.9844Z"
      fill="#58CC02"
    />
  </svg>
);
